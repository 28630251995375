*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

body {
  overflow-x: hidden;
}


.tech-holder__container {
  max-width: 1400px;
  padding: 41px;
  margin: auto;
  padding-top: 73px;
  height: 100vh;
  position: relative;
}

.tech-holder__header {
  position: absolute;
  padding: 0 41px;
  top: 31px;
  left: 0;
}

.tech-holder__title {
  font-size: 39px;
  line-height: 52px;
  font-weight: normal;
  margin-bottom: 52px;
  color: #4f4f4f;
  letter-spacing: 0.0025em;
}

.tech-holder__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
  z-index: 15;
}

.tech-holder-dates {
  position: relative;
  padding: 4px 16px;
  margin-bottom: 44px;
}

.tech-holder-dates:after {
  content: '';
  display: block;
  background-color: #c9d0d8;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  width: 4px;
  border-radius: 3px;
}

.tech-holder-dates__title {
  font-size: 16px;
  line-height: 19px;
  color: #767676;
}

.tech-holder-dates__end {
  font-size: 22px;
  line-height: 35px;
  color: #4f4f4f;
}

.tech-holder-help {
  font-size: 16px;
  line-height: 19px;
  /* 767676 */
  margin-bottom: 44px;
  color: #767676;
}

.tech-holder-help__description {
  margin-bottom: 20px;
}

.tech-holder-help__point {
  margin-top: 12px;
}

.tech-holder-help__point a {
  text-decoration: none;
  color: inherit;
  font-size: 22px;
  line-height: 26px;
  color: #4f4f4f;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.tech-holder-help__point svg {
  margin-right: 12px;
}

.tech-holder-managers__description {
  font-size: 16px;
  line-height: 19px;
  /* 767676 */
  color: #767676;
  margin-bottom: 28px;
}

.tech-holder-managers__download {
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  text-decoration: none;
  padding: 9px 16px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #d77721;
  border-radius: 50px;
  cursor: pointer;
}

.tech-holder-managers__download:hover {
  opacity: 0.9;
}

.tech-holder-managers__download svg {
  margin-right: 4px;
}

.tech-holder-figure {
  position: absolute;
  top: 50%;
  right: -20%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  height: 100vh;
}

@media all and (max-width: 950px) {
  .tech-holder-figure {
    display: none;
  }
}

@media all and (max-width: 650px) {
  .tech-holder__container {
    height: auto;
    margin-top: 40p;
    padding-top: 150px;
  }
  .tech-holder-help__point a {
    font-size: 18px;
  }
}